import "./style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { RefresherEventDetail } from "@ionic/core";
import { IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import { LinearProgress, Stack } from "@mui/material";
import { AgentStage } from "@src/appV2/Agents";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { AttendanceScoreRecentActivity } from "@src/appV2/AttendanceScore/attendanceScoreRecentActivity";
import { useScoreHistoryTableIsEnabled } from "@src/appV2/AttendanceScore/featureFlagHooks";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";

import { AttendanceScorePolicyDetailCard } from "./attendanceScorePolicyDetailCard";
import {
  ATTENDANCE_SCORE_THRESHOLD_FOR_LATENESS_WARNING,
  LATENESS_DEDUCTED_POINTS_THRESHOLD_FOR_WARNING,
} from "./constants";
import { useTimelinessPointDeductedWarningEnabled } from "./featureFlagHooks";
import { LatenessDeductionWarningAlert } from "./LatenessDeductionWarningAlert";
import { LearnMoreCard } from "./learnMoreCard";
import { MyAttendanceScoreContainer } from "./myAttendanceScoreContainer";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { useSession } from "../store/helperHooks";

export function AttendanceScoreRatingPage() {
  const { agent } = useSession();

  const attendanceScorePolicyLinks = useCbhFlag(CbhFeatureFlag.ATTENDANCE_SCORE_POLICY_LINKS, {
    defaultValue: {},
  });
  const isTimelinePointDeductedWarningEnabled = useTimelinessPointDeductedWarningEnabled();
  const isAttendaceScoreHistoryTableEnabled = useScoreHistoryTableIsEnabled();

  const {
    isLoading,
    data: attendaceScoreProfile,
    isSuccess,
    refetch,
  } = useGetAttendanceScoreProfile(agent?.userId);

  const statusPolicyLink = {
    label:
      attendanceScorePolicyLinks?.understandingAccountStatus?.label ??
      "Understanding Your Account Status",
    url:
      attendanceScorePolicyLinks?.understandingAccountStatus?.url ?? SUPPORT_LINKS.ACCOUNT_STATUS,
  };

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Attendance Score"
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.RATINGS} />}
          />
        }
        containerVariant="without-margin"
        containerMaxWidth="md"
        onRefresh={refetch}
      >
        <IonRefresher
          slot="fixed"
          onIonRefresh={(refreshEvent?: CustomEvent<RefresherEventDetail>) => {
            refetch();
            refreshEvent?.detail.complete();
          }}
        >
          <IonRefresherContent />
        </IonRefresher>

        {(!agent || isLoading) && (
          <LinearProgress sx={{ height: "4px" }} data-testid="attendance-score-progressbar" />
        )}

        {isSuccess && isDefined(agent) && (
          <>
            <MyAttendanceScoreContainer
              score={attendaceScoreProfile.score}
              accountStatus={attendaceScoreProfile.accountStatus}
              policyDetailUrl={
                attendanceScorePolicyLinks?.howAttendanceWorks?.url ||
                SUPPORT_LINKS.ATTENDANCE_SCORE
              }
              tmz={agent.tmz}
            />
            {isDefined(attendaceScoreProfile?.accountStatus?.status) &&
              attendaceScoreProfile.accountStatus.status === AgentStage.ENROLLED &&
              isTimelinePointDeductedWarningEnabled &&
              attendaceScoreProfile.score < ATTENDANCE_SCORE_THRESHOLD_FOR_LATENESS_WARNING &&
              (attendaceScoreProfile.latenessPointsDeductedInLast30Days ?? 0) >
                LATENESS_DEDUCTED_POINTS_THRESHOLD_FOR_WARNING && (
                <Stack sx={{ padding: "16px", paddingBottom: "0px" }}>
                  <LatenessDeductionWarningAlert attendaceScoreProfile={attendaceScoreProfile} />
                </Stack>
              )}
            {attendaceScoreProfile.policy && (
              <AttendanceScorePolicyDetailCard policy={attendaceScoreProfile.policy} />
            )}
            {isAttendaceScoreHistoryTableEnabled && isDefined(agent.userId) && (
              <Stack sx={{ paddingLeft: "16px", paddingRight: "16px" }}>
                <AttendanceScoreRecentActivity
                  workerUserId={agent.userId}
                  restrictionCount={attendaceScoreProfile?.restrictionCount}
                  restrictionDuration={attendaceScoreProfile?.policy?.restriction?.[0]?.duration}
                  finalRestriction={attendaceScoreProfile?.policy?.finalRestriction}
                />
              </Stack>
            )}
            <LearnMoreCard
              links={[
                {
                  label:
                    attendanceScorePolicyLinks?.howAttendanceWorks?.label ??
                    "How the Attendance Score Works",
                  url:
                    attendanceScorePolicyLinks?.howAttendanceWorks?.url ||
                    SUPPORT_LINKS.ATTENDANCE_SCORE,
                },
                statusPolicyLink,
                {
                  label:
                    attendanceScorePolicyLinks?.doNotReturnPolicy?.label ??
                    "Do Not Return (DNR) Policy",
                  url:
                    attendanceScorePolicyLinks?.doNotReturnPolicy?.url ||
                    SUPPORT_LINKS.DNR_REQUESTS_WORKER,
                },
              ]}
            />
          </>
        )}
      </PageWithHeader>
    </IonPage>
  );
}
