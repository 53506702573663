import "./style.scss";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonCard, IonItem, IonLabel, IonNote } from "@ionic/react";
import { Box, Stack } from "@mui/material";
import { AttendanceScoreProfileResponse } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

import { DEFAULT_CLOCK_IN_BONUS_POINTS } from "./constants";

export const AttendanceScorePolicyDetailCard = (
  props: Required<Pick<AttendanceScoreProfileResponse, "policy">>
) => {
  const { policy } = props;
  const { workShift, cancelShift, noCallNoShow, shiftClockInConfig, latenessConfig } = policy;
  const workWithFriendsBonuses = useCbhFlag(CbhFeatureFlag.WORK_WITH_FRIENDS_BONUSES, {
    defaultValue: {},
  });

  const formatTimeInterval = (timeInterval: (number | null | undefined)[]) => {
    const formattedIntervalEnd = isDefined(timeInterval[1]) ? `-${timeInterval[1]}` : "+";
    return String(timeInterval[0]) + formattedIntervalEnd;
  };

  const formatRangeInterval = (interval: { startFrom: number; stopAt?: number }) => {
    const formattedIntervalEnd = isDefined(interval.stopAt) ? `-${interval.stopAt}` : "+";
    return String(interval.startFrom) + formattedIntervalEnd;
  };

  return (
    <IonCard
      class="attendance-score-policy-detail"
      data-testid="attendance-score-policy-detail-card"
    >
      <Stack spacing={2}>
        <div className="attendance-score-policy-detail-header">
          <IonItem lines="none">
            <h1 className="ratings-card-title">Attendance Score Guide</h1>
          </IonItem>
          <IonItem lines="none" className="ratings-card-sub-title">
            How attendance score changes based on your activity
          </IonItem>
        </div>

        <Box>
          {isDefined(workShift?.points) && (
            <IonItem lines="none">
              <IonLabel slot="start">Work a shift</IonLabel>
              <IonNote slot="end" color="success" aria-label="Work Shift Points">
                +{workShift?.points}
              </IonNote>
            </IonItem>
          )}

          {isDefined(workWithFriendsBonuses.groupAttendanceBonus) &&
            workWithFriendsBonuses.groupAttendanceBonus > 0 && (
              <IonItem lines="none">
                <IonLabel className="group-attendance-score-label" slot="start">
                  All friends worked a shift, per friend
                </IonLabel>
                <IonNote
                  slot="end"
                  color="success"
                  data-testid="attendance-score-policy-wwf-attendance-bonus"
                >
                  +{workWithFriendsBonuses.groupAttendanceBonus}
                </IonNote>
              </IonItem>
            )}

          {isDefined(noCallNoShow?.points) && (
            <IonItem lines="none">
              <IonLabel slot="start">No Call No Show</IonLabel>
              <IonNote slot="end" color="danger">
                {noCallNoShow?.points}
              </IonNote>
            </IonItem>
          )}
        </Box>
        {isDefined(cancelShift) && cancelShift.length > 0 && (
          <Box>
            <Text textAlign="left" fontStyle="italic" marginLeft={2}>
              Cancel a shift:
            </Text>

            {cancelShift.map((shiftCancelConfig) => {
              const { leadTime, points } = shiftCancelConfig;
              const formattedLeadTime = formatTimeInterval(leadTime);
              return (
                <IonItem lines="none" key={formattedLeadTime}>
                  <IonLabel>{formattedLeadTime} hours in advance</IonLabel>
                  <IonNote slot="end" color="danger">
                    {points}
                  </IonNote>
                </IonItem>
              );
            })}
          </Box>
        )}

        <Box>
          <Text textAlign="left" fontStyle="italic" marginLeft={2}>
            Timeliness:
          </Text>
          <IonItem lines="none">
            <IonLabel slot="start">Clocking in on time</IonLabel>
            <IonNote slot="end" color="success" aria-label="Clock In Bonus Points">
              +{shiftClockInConfig?.points ?? DEFAULT_CLOCK_IN_BONUS_POINTS}
            </IonNote>
          </IonItem>
          {isDefined(latenessConfig) && (
            <>
              {latenessConfig.map((latenessConfig) => {
                const { minutesLateRange, points } = latenessConfig;
                const formattedMinuteRange = formatRangeInterval(minutesLateRange);
                return (
                  <IonItem lines="none" key={formattedMinuteRange}>
                    <IonLabel>{`Clocking in ${formattedMinuteRange} minutes late`}</IonLabel>
                    <IonNote slot="end" color="danger">
                      {points}
                    </IonNote>
                  </IonItem>
                );
              })}
            </>
          )}
        </Box>
      </Stack>
    </IonCard>
  );
};
