import { NodeEnvironment, environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";
import { z } from "zod";

import { getAuthHeader } from "../../superagent";

const attendanceScoreCancellationPolicySchema = z.object({
  action: z.string(),
  leadTime: z.number(),
  policyId: z.number(),
  policy: z
    .object({
      leadTime: z.array(z.number().nullable()).optional(),
      points: z.number().nullish(),
    })
    .optional(),
  scoreResult: z.number(),
  message: z.string().optional(),
});

export type AttendanceScoreCancellationPolicyResponse = z.infer<
  typeof attendanceScoreCancellationPolicySchema
>;

// FIXME: Migrate this hook to appV2 and rework to use useGetQuery
// Also, remove schema parsing as it is done implictly in appV2
export function getAttendanceScoreCancellationPolicyPath(
  agentId: string,
  leadTime: number
): string {
  return `/cancellations/policy?workerUserId=${agentId}&leadTime=${leadTime.toString()}`;
}

export const getAttendanceScoreCancellationPolicy = async (
  agentId: string,
  leadTime: number
): Promise<AttendanceScoreCancellationPolicyResponse> => {
  try {
    const { body } = await request
      .get(
        `${
          environmentConfig.REACT_APP_ATTENDANCE_SCORE_SERVICE_URL
        }${getAttendanceScoreCancellationPolicyPath(agentId, leadTime)}`
      )
      .set(await getAuthHeader());

    if (process.env.NODE_ENV !== NodeEnvironment.DEVELOPMENT) {
      return body;
    }

    return attendanceScoreCancellationPolicySchema.parse(body);
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};
