import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

import {
  AttendanceScoreCancellationPolicyResponse,
  getAttendanceScoreCancellationPolicy,
  getAttendanceScoreCancellationPolicyPath,
} from "./getAttendanceScoreCancellationPolicy";

export function useAttendanceScoreCancellationPolicy(
  leadTime: number,
  canCancelWithoutPenalty: boolean,
  userId?: string,
  options: UseQueryOptions<AttendanceScoreCancellationPolicyResponse> = {}
): UseQueryResult<AttendanceScoreCancellationPolicyResponse> {
  return useQuery<AttendanceScoreCancellationPolicyResponse>(
    [getAttendanceScoreCancellationPolicyPath(userId ?? "", leadTime)],
    () => getAttendanceScoreCancellationPolicy(userId ?? "", leadTime),
    { enabled: !canCancelWithoutPenalty && Boolean(userId), ...options }
  );
}
